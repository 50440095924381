import { http } from './config'
import authHeader from './auth-header';

export default {


    

    lista_cliente_falecidos: () => {
        return http.get('/auditoria-dash/lista-cliente-falecidos/', {
           //params: params,
           headers: authHeader()   
        })  
    },

    lista_boletos_ativos_sem_cpf_xls: () => {
      return http.get('/planilha-dash/lista-boletos-sem-cpf-xls/', {
        //params: params,
        responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
        headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

      });
    
  },

  lista_boletos_ativos_sem_endereco_xls: () => {
    return http.get('/planilha-dash/lista-boletos-sem-endereco-xls/', {
      //params: params,
      responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
      headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

    });
  
},


  

    total_boletos_sem_cpf: () => {
      return http.get('/auditoria-dash/total-boletos-sem-cpf/', {
         //params: params,
         headers: authHeader()   
      })  
    },

    total_boletos_sem_endereco: () => {
      return http.get('/auditoria-dash/total-boletos-sem-endereco/', {
         //params: params,
         headers: authHeader()   
      })  
    },

    total_cliente_falecidos: () => {
        return http.get('/auditoria-dash/total-cliente-falecidos/', {
           //params: params,
           headers: authHeader()   
        })  
    },

    total_cpf: () => {
      return http.get('/auditoria-dash/total-cpf-auditoria/', {
         //params: params,
         headers: authHeader()   
      })  
    },


    total_contrato_sem_boleto: () => {
      return http.get('/auditoria-dash/total-contrato-sem-boleto/', {
         //params: params,
         headers: authHeader()   
      })  
    },



    total_cliente_nascimento_auditoria: () => {
        return http.get('/auditoria-dash/total-cliente-nascimento-auditoria/', {
           //params: params,
           headers: authHeader()   
        })  
    },


      lista_cpf_xls: () => {

                return http.get('/planilha-dash/lista-cpf-auditoria-xls', {
                  //params: params,
                  responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                  headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
      
                });
      },

      lista_sem_boleto_xls: () => {

             
        return http.get('/planilha-dash/lista-sem-boleto-xls', {
          //params: params,
          responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
          headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

        });

        
},


    lista_cliente_falecidos_aud_xls: () => {
/*
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
           // 'filtro': filtro,
            'campanha': opcao
          };
*/
       
          return http.get('/planilha-dash/lista-cliente-falecidos-aud-xls', {
            //params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });

          
    },

    lista_cliente_nascimento_erro_xls: () => {
        /*
                const params = {
                    'data-inicial': dataI,
                    'data-final': dataF,
                    'empresa': nomeEmpresa,
                    'tipo': tipo,
                   // 'filtro': filtro,
                    'campanha': opcao
                  };
        */
               
                  return http.get('/planilha-dash/lista-cliente-nascimento-erro-xls', {
                    //params: params,
                    responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                    headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
        
                  });
        
                  
            },


   



    


    
    
    

   

}
